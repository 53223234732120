<template>
  <b-container fluid id="faq">
    <h1>SHELTA PROPERTY SALES PROCEDURE FAQS</h1>
    <div class="mt-5">
      <h3>
        1. As a property owner (seller), do I need to provide any documents to
        Shelta?
      </h3>
      <div class="pl-1 pl-lg-4">
        <h6>Yes you need to provide the following:</h6>
        <ul>
          <li>Title documents</li>
          <li>Affidavit of ownership</li>
          <li>Valid means of identification</li>
          <li>Completed form containing necessary details</li>
        </ul>
      </div>
    </div>
    <div class="mt-5">
      <h3>2. What will suffice as a valid means of identification?</h3>
      <div class="pl-1 pl-lg-4">
        <h6>International Passport, Drivers License or National ID Card</h6>
      </div>
    </div>
    <div class="mt-5">
      <h3>3. Is there any other sort of agreement needed?</h3>
      <div class="pl-1 pl-lg-4">
        <h6>
          Yes. Shelta and the property owner (seller) enter into a deed of
          agreement exclusively mandating Shelta to sell the property at a
          particular price
        </h6>
      </div>
    </div>
    <div class="mt-5">
      <h3>4. Who decides the selling price of the property?</h3>
      <div class="pl-1 pl-lg-4">
        <h6>
          Selling price of the property is at discretion of the seller. Shelta
          can advise but the ultimate decision lies with the seller.
        </h6>
      </div>
    </div>
    <div class="mt-5">
      <h3>5. How does Shelta go about marketing the property?</h3>
      <div class="pl-1 pl-lg-4">
        <h6>
          Shelta lists the said property on her platform (website and social
          media handles) for interested buyers.
        </h6>
      </div>
    </div>
    <div class="mt-5">
      <h3>6. Are there any obligations on an interested buyer?</h3>
      <div class="pl-1 pl-lg-4">
        <h6>
          Yes. An interested buyer is expected to carry out a Legal Search on
          the property for proper verification.
        </h6>
      </div>
    </div>
    <div class="mt-5">
      <h3>7. Can Shelta assist an interested buyer with the Legal Search?</h3>
      <div class="pl-1 pl-lg-4">
        <h6>
          Shelta can assist with the Legal Search for the interested buyer for a
          fee.
        </h6>
      </div>
    </div>
    <div class="mt-5">
      <h3>
        8. Having completed verification and the seller's title to the property
        is confirmed, what is the next step?
      </h3>
      <div class="pl-1 pl-lg-4">
        <ul>
          <li>Negotiations between buyer and seller are concluded</li>
          <li>Buyer pays to seller</li>
          <li>
            Deed of Assignment/Power of Attorney/Transfer of Ownership is signed
            between seller and buyer
          </li>
        </ul>
      </div>
    </div>
    <div class="mt-5">
      <h3>
        9. Does the money to be paid for the property go through Shelta's
        account?
      </h3>
      <div class="pl-1 pl-lg-4">
        <h6>
          No. Purchase payment for property is paid straight to the seller's
          account
        </h6>
      </div>
    </div>
    <div class="mt-5">
      <h3>10. Who prepares the transfer of title documents?</h3>
      <div class="pl-1 pl-lg-4">
        <h6>
          Documents are to be prepared by the buyer's lawyer. Shelta can also
          prepare the document for a fee.
        </h6>
      </div>
    </div>
    <div class="mt-5">
      <h3>
        11. Having signed all necessary documents between buyer and seller, what
        is the next step?
      </h3>
      <div class="pl-1 pl-lg-4">
        <h6>Buyer takes full possession of the property</h6>
      </div>
    </div>
    <div class="mt-5">
      <h3>
        12. Does utilising Shelta's services for sales attract any charges?
      </h3>
      <div class="pl-1 pl-lg-4">
        <h6>
          Yes. The buyer pays a 2% fee for admin/documentation while the seller
          attracts a fee of 5% fo the value of the property sale.
        </h6>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {};
</script>

<style>
#faq {
  margin-top: 10em;
  margin-bottom: 5em;
}
</style>
